import { css } from '@emotion/react';
import { theme, useMediaQueryState } from '@minted/minted-components';
import PropTypes from 'prop-types';
import React from 'react';

import SiteCard from './SiteCard';
import { SITE_TYPES } from '../../core/constants';

const sectionStyles = {
  archivedNote: css`
    ${theme.typeStyles.bodySmallStyles};
  `,
  column: css`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x4};
    width: 100%;
  `,
  emptySection: css`
    ${theme.typeStyles.bodyExtraSmallStyles};
  `,
  row: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing.x4};
    justify-content: flex-start;
    width: 100%;
  `,
  title: (isMobile) => css`
    ${isMobile ? theme.typeStyles.bodyLargeStyles : theme.typeStyles.bodyExtraLargeStyles};
  `,
  wrapper: css`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x4};
    margin-top: ${theme.spacing.x4};
    width: 100%;
  `,
};

const sectionTitles = {
  active: 'ACTIVE',
  archived: 'ARCHIVED',
  deleted: 'RECENTLY DELETED',
};

const sectionFilters = {
  active: (sites) => sites.filter((site) => (site.status === 'published')),
  archived: (sites) => sites.filter((site) => (site.status === 'archived')),
  deleted: (sites) => sites.filter((site) => site.status === 'deleted'),
};

const EmptySection = ({
  sectionType,
}) => (
  <div css={sectionStyles.emptySection}>
    {`You do not have any ${sectionType} sites right now.`}
  </div>
);

const SiteSection = ({
  sectionType, setErrorMessage, sites,
}) => {
  const filteredSites = sectionFilters[sectionType](sites);
  const mediumMediaQueryState = useMediaQueryState({
    mediaQuerySize: 'medium',
  });
  const isMobile = mediumMediaQueryState === 'BELOW';

  if (sectionType === 'deleted' && filteredSites.length === 0) {
    return null;
  }

  if (
    sectionType === 'archived' &&
    sites.every((site) => site.siteType === SITE_TYPES.WEDDING)
  ) {
    return null;
  }

  return (
    <div
      css={sectionStyles.wrapper}
      data-cy={`${sectionType}SitesSection`}
    >
      <div css={sectionStyles.title(isMobile)}>
        {sectionTitles[sectionType]}
      </div>
      {/* Seems like this goes away in figma, need to confirm */}
      {
        sectionType === 'archived' &&
            (
              <div css={sectionStyles.archivedNote}>
                Holiday Websites are archived 120 days after they're created.
              </div>
            )
      }
      <div css={isMobile ? sectionStyles.column : sectionStyles.row}>
        {
          filteredSites.map((site) => (
            <SiteCard
              key={site.id}
              sectionType={sectionType}
              setErrorMessage={setErrorMessage}
              site={site}
            />
          ))
        }
      </div>
      {filteredSites.length === 0 && <EmptySection sectionType={sectionType} />}
    </div>
  );
};

EmptySection.propTypes = {
  sectionType: PropTypes.string.isRequired,
};

SiteSection.propTypes = {
  sectionType: PropTypes.string.isRequired,
  setErrorMessage: PropTypes.func,
  sites: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SiteSection;
