import { css } from '@emotion/react';
import {
  styleUtils, theme, Alert,
} from '@minted/minted-components';
import partition from 'lodash/partition';
import PropTypes from 'prop-types';
import {
  React, useState, useCallback,
} from 'react';

import SiteSection from './SiteSection';
import { SITE_TYPES } from '../../core/constants';
import SiteFooter from '../../core/dashboard/SiteFooter';
import NavigationContext from '../../navigation/dashboard/NavigationContext';
import SiteNavigation from '../../navigation/dashboard/SiteNavigation';

const sitePickerStyles = {
  background: css`
    background-color: ${theme.colors.gray100};
  `,
  wrapper: css`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: ${theme.spacing.x4};
    padding-left: ${styleUtils.rem(20)};
    padding-right: ${styleUtils.rem(20)};
  `,
};

const sectionTypes = [
  'active',
  'archived',
  'deleted',
];

const SitePicker = ({
  navBarProps,
  sites,
}) => {
  const [
    errorMessage,
    setErrorMessage,
  ] = useState('');
  const orderedSites = partition(sites, (site) => site.siteType === SITE_TYPES.WEDDING).flat(1);

  const clearAlert = useCallback(() => {
    setErrorMessage('');
  }, []);

  return (
    <div css={sitePickerStyles.background}>
      <NavigationContext.Provider value={navBarProps}>
        <SiteNavigation />
      </NavigationContext.Provider>

      <Alert
        onCloseClick={clearAlert}
        secondaryText={errorMessage}
        show={!!errorMessage}
        type={Alert.types.negative}
      />
      <div css={sitePickerStyles.wrapper}>
        {
          sectionTypes.map((sectionType)=> (
            <SiteSection
              key={sectionType}
              sectionType={sectionType}
              setErrorMessage={setErrorMessage}
              sites={orderedSites}
            />
          ))
        }
      </div>
      <SiteFooter />

    </div>
  );
};

SitePicker.propTypes = {
  sites: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SitePicker;
